export interface Lista {
  listID: string;
  nome: string;
  candidati: Candidato[];
  logo: string;
}

export enum Gender {
  M,
  F,
}

export interface Candidato {
  nome: string;
  cognome: string;
  listID: string;
  sesso: Gender;
}

export const arrListe = [
  {
    listID: "4",
    nome: "Per le persone e la comunità",
    candidati: [
      {
        nome: "Riccardo",
        cognome: "Abbondante",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Silvio",
        cognome: "Acone",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Miriam",
        cognome: "Ambrosanio",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Giovanni",
        cognome: "Antelitano",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Arianna",
        cognome: "Bernardo",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Martina",
        cognome: "Caianello",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Lorenzo",
        cognome: "D'aniello",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Flavia",
        cognome: "De Caro",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Dello Iacono",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Miriam",
        cognome: "Dericoloso",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Michele",
        cognome: "D'Urso",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Gennaro (detto GENNY)",
        cognome: "Esposito",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Sara",
        cognome: "Finamore",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Giuseppe",
        cognome: "Galiero",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Fabrizia",
        cognome: "Gargiulo",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Lucrezia",
        cognome: "Grima",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Luongo",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Junior Even Fernando (detto IVAN FERNANDO)",
        cognome: "Maha Warnakulasuriya",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Simone",
        cognome: "Manco",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Gaetano",
        cognome: "Manzari",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Antonio",
        cognome: "Manzo",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Maria",
        cognome: "Maglione",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Gabriele",
        cognome: "Nardiello",
        listID: "4",
        sesso: Gender.M,
      },
      {
        nome: "Mara",
        cognome: "Noto",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Maria",
        cognome: "Pizzo",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Clara",
        cognome: "Rispoli",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Francesca",
        cognome: "Romano",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Sarah Mariaisa (detta SARA)",
        cognome: "Sabia",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Davide",
        cognome: "Vaccaro",
        listID: "4",
        sesso: Gender.F,
      },
      {
        nome: "Danilo",
        cognome: "Vespero",
        listID: "4",
        sesso: Gender.F,
      },
    ],
    logo: "per_le_persone",
  },

  {
    listID: "5",
    nome: "Napoli ai giovani - Uniti per il futuro",
    candidati: [
      {
        nome: "Bianca",
        cognome: "Amoroso",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Eugenia Vittoria",
        cognome: "Cabib",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Lorenzo",
        cognome: "Caldarone",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Emanuela",
        cognome: "Cinquegrana",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Assunta",
        cognome: "Criscuolo",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Mirko",
        cognome: "Corpo",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Vincenzo",
        cognome: "De Angelis",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Amedeo",
        cognome: "Di Maio",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Alessandra",
        cognome: "Egizzo",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Emanuele",
        cognome: "Esposito",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Marco Angelo",
        cognome: "Falanga",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Giada",
        cognome: "Gentile",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Mariottino",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Marco",
        cognome: "Merolla",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Oriana",
        cognome: "Orzetti",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Marco",
        cognome: "Paternoster",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Maria Rosaria",
        cognome: "Perez",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Rossella",
        cognome: "Pirotti",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Ludovica",
        cognome: "Proietti",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Quaranta",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Sergio",
        cognome: "Russo",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Yauheni (detto EUGENIO)",
        cognome: "Salauyeu",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Jennifer",
        cognome: "Scurti",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Francesca",
        cognome: "Sebastiano",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Angelo Andrea",
        cognome: "Simula",
        listID: "5",
        sesso: Gender.M,
      },
      {
        nome: "Marianna",
        cognome: "Troiano",
        listID: "5",
        sesso: Gender.F,
      },
      {
        nome: "Giovanni",
        cognome: "Vecchione",
        listID: "5",
        sesso: Gender.F,
      },
    ],
    logo: "napoli_ai_giovani",
  },

  {
    listID: "2",
    nome: "Agorà - Giovani per Napoli",
    candidati: [
      {
        nome: "Gaia",
        cognome: "Belfiore",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Mariachiara",
        cognome: "Aliperti",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Baratto",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Marco",
        cognome: "Cavaliere",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Mattia",
        cognome: "Contemi",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Gianluca",
        cognome: "Coppola",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Mattia",
        cognome: "Del Gaudio",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Vincenzo",
        cognome: "Di Donato",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Pierluca",
        cognome: "Di Falco",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Francesco Paolo",
        cognome: "Esposito",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Cristian",
        cognome: "Esposito",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Valeria",
        cognome: "Femia",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Raffaella Vittoria",
        cognome: "Filia",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Chiara",
        cognome: "Iannuzzo",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Emanuele",
        cognome: "Lauria",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Simone",
        cognome: "Magro",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Rosario",
        cognome: "Marrapodi",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Brenda Del Carmen",
        cognome: "Massimo Esposito",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Leonardo",
        cognome: "Menniti",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Maria",
        cognome: "Miragliuolo",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Mattia",
        cognome: "Moccia",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Veronica",
        cognome: "Pintus",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Marco",
        cognome: "Pisanelli",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Massimiliano",
        cognome: "Punzo",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Davide",
        cognome: "Ramondini",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Cristina",
        cognome: "Somma",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Miro",
        cognome: "Sorrentino",
        listID: "2",
        sesso: Gender.M,
      },
      {
        nome: "Federica",
        cognome: "Terribile",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Serena",
        cognome: "Tuccillo",
        listID: "2",
        sesso: Gender.F,
      },
      {
        nome: "Micaela",
        cognome: "Vitagliano",
        listID: "2",
        sesso: Gender.F,
      },
    ],
    logo: "agora",
  },

  {
    listID: "3",
    nome: "Briganti Napoletani Indipendenza!",
    candidati: [
      {
        nome: "Matilde",
        cognome: "Tarantino",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Riccardo",
        cognome: "Capano",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Pasqualina",
        cognome: "Altamura",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Giuseppe",
        cognome: "Coppola",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Carmela",
        cognome: "Caruso",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Alessandro",
        cognome: "Colucci",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Laura",
        cognome: "Guadagno",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Davide",
        cognome: "De Palma",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Carolina",
        cognome: "Pecorella",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Alfonso",
        cognome: "Monfrecola",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Carmen",
        cognome: "Nicosia",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Nervo",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Maria Nunzia",
        cognome: "Orefice",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Giuseppe",
        cognome: "Raccuglia",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Giulia",
        cognome: "Vasaturo",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Vincenzo",
        cognome: "Romano",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Maria Nunzia",
        cognome: "Visone",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Leandro",
        cognome: "Turino",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Adriana",
        cognome: "Grimaldi",
        listID: "3",
        sesso: Gender.F,
      },
      {
        nome: "Renato",
        cognome: "Tedesco",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Antonio",
        cognome: "Visone",
        listID: "3",
        sesso: Gender.M,
      },
      {
        nome: "Andrea Francesco",
        cognome: "Duyrat",
        listID: "3",
        sesso: Gender.M,
      },
    ],
    logo: "briganti",
  },
  {
    listID: "1",
    nome: "Napoli Social Forum",
    candidati: [
      {
        nome: "Alessandro",
        cognome: "Maietta",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Davide",
        cognome: "Di Costanzo",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Andrea Sofia",
        cognome: "Luciano",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Alessandro",
        cognome: "Citarelli",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Gianluca",
        cognome: "Albrizio",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Vincenzo",
        cognome: "Franceschini",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Monica",
        cognome: "De Franco",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Antonio",
        cognome: "Caiazzo",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Annachiara",
        cognome: "Barbuto",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Elisabetta",
        cognome: "Ascione",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Ludovica",
        cognome: "Aversano",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Claudia",
        cognome: "Criscuolo",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Emanuele Salvatore",
        cognome: "Criscuolo",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Federica",
        cognome: "Giglio",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Maria",
        cognome: "Ambrosanio",
        listID: "1",
        sesso: Gender.F,
      },
      {
        nome: "Giuseppe",
        cognome: "Ruocco",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Alberto Francesco",
        cognome: "Coppola",
        listID: "1",
        sesso: Gender.M,
      },
      {
        nome: "Stefania Daniela",
        cognome: "Russo",
        listID: "1",
        sesso: Gender.F,
      },
    ],
    logo: "napoli_social_forum",
  },
];
