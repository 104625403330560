
export enum View{
    candidates, register, otpcode, responseSuccess, responseFailedGenerale, responseFailedOtp,
}

export enum Response{
    success, failedGenerale, responseFailedOtp
}

// export enum ErrorMessage{
//     general, email, phoneNumber, otpCode
// }


// export function parseErrorMessage(errorMessage : ErrorMessage){
//     switch(errorMessage){
//         case ErrorMessage.general:
//             return "La procedura di identificazione non è stata completata correttamente";
//         case ErrorMessage.email:
//             return "L'email che hai inserito non è valida";
//         case ErrorMessage.phoneNumber:
//            return "Il numero di cellulare che hai inseriro non corrisponde ad una sim reale"
//         case ErrorMessage.otpCode:
//             return "Il codice OTP inserito è errato";
//       }
// }
