import styles from "./header.module.scss";
import camelotLogo from "../../libs/assets/camelotLogo.svg";
import comuneLogo from "../../libs/assets/comuneLogo.svg";
import { View } from "../../models/step_enum";


const Header = (props: {currentView: View}) => {
    return(
        <div className={styles.main}>
            <div className={styles._camelotLogo}>
                <img src={camelotLogo} alt={"logo"}/>
            </div>
            <div className={styles._title}>
                <div className={styles.__firstWrap}>
                <img src={comuneLogo} alt={"comuneDiNapoli"}/>
                <div className={styles.__titleText}>
                    <p>ELEZIONE FORUM GIOVANI NAPOLI</p>
                    <p>11 - 16 Dicembre 2023</p>
                </div>
                </div>
                
                <div className={styles.__tag} >
                    <p style={{margin: "0"}}>{props.currentView === View.candidates ? "PROCEDURA DI VOTO" : "REGISTRAZIONE AL VOTO"}</p>
                </div>
            </div>
           {props.currentView !== View.candidates ? null : <div className={styles._description}>
                <p>Dal 11 al 16 Dicembre si terranno le votazioni per eleggere il forum dei Giovani del Comune di Napoli. Le liste candidate sono 5 per un totale di 127 candidati pronti a rappresentare i giovani napoletani. Potrai votare con modalità digitale o in presenza fisica seguendo le indicazioni di dettaglio.</p>
            </div>}
        </div>
    )
}

export default Header;