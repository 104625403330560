import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Response, View } from "../../models/step_enum";
import styles from "./register.module.scss";
import RegisterFormView from "../../components/RegisterFormView";
import InsertOtpCodeView from "../../components/InsertOtpCodeView";
import ResponseView from "../../components/ResponseView";
import { Registration } from "../../models/registration";
import { registrationPOST } from "../../services/registration_post";
import { otpPOST } from "../../services/otp_post";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { resendOTP } from "../../services/resend_otp";
import { useNavigate } from "react-router";

export default function Register() {
  const [currentView, setCurrentView] = useState(View.register);
  const [registrationID, setRegistrationID] = useState<string>();
  const [errorOtpMessage, setErrorOtpMessage] = useState<string>();
  const [onExecute, setOnExecute] = useState<boolean>();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  async function sendRegistration(registration: Registration) {
    const token = await executeRecaptcha!("submit");
    console.log(token);
    setOnExecute(true);
    try {
      const resp = await registrationPOST({ ...registration, token: token });
      if (resp?.statusCode === 401 || resp?.statusCode === 400) {
        setCurrentView(View.responseFailedGenerale);
        setOnExecute(false);
      } else {
        setRegistrationID(resp?.responseBody);
        setCurrentView(View.otpcode);
        setOnExecute(false);

        console.log("qui" + registrationID);
      }
    } catch (error) {
      console.log(error);
      setOnExecute(false);
    }
  }

  async function sendOTPCode(registrationID: string, otp: string) {
    try {
      const resp = await otpPOST(registrationID, otp);
      setErrorOtpMessage(resp != undefined ? resp.responseBody : "");
      if (resp?.statusCode === 400) {
        setCurrentView(View.responseFailedOtp);
      } else {
        setCurrentView(View.responseSuccess);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function resendOTPCode(registrationID: string) {
    try {
      const resp = await resendOTP(registrationID);
      setErrorOtpMessage(resp !== undefined ? resp.responseBody : "");
      if (resp?.statusCode === 400) {
        navigate("/");
      } else {
        setCurrentView(View.otpcode);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(onExecute);
  }, [onExecute]);

  return (
    <div className={styles.main}>
      <div className={styles._header}>
        <Header currentView={View.register} />
      </div>

      <div className={styles._body}>
        {
       currentView !== View.responseFailedOtp 
       && currentView !== View.responseFailedGenerale 
        && currentView !== View.responseSuccess
        ? <p style={{ margin: "0" }}>
          COMPLETA LA PROCEDURA DI IDENTIFICAZIONE PER PARTECIPARE ALLA
          VOTAZIONE
        </p> : null}
        {currentView === View.register ? (
          <div className={styles._body}>
            <RegisterFormView
              onSubmit={(e) => {
                sendRegistration(e);
              }}
              onExecute={onExecute != null ? onExecute : false}
            />
          </div>
        ) : currentView === View.otpcode ? (
          <div className={styles._body}>
            <InsertOtpCodeView
              onSubmit={async (e) => {
                await sendOTPCode(registrationID!, e);
              }}
              editPhone={() => setCurrentView(View.register)}
              resendOtp={() => {
                resendOTPCode(registrationID!);
              }}
            />
          </div>
        ) : currentView === View.responseFailedGenerale ? (
          <div className={styles._body}>
            <ResponseView
              response={Response.failedGenerale}
              errorMessage={
                "La procedura di identificazione non è stata completata correttamente"
              }
              toForm={() => {
                setCurrentView(View.register);
              }}
            />
          </div>
        ) : currentView === View.responseFailedOtp ? (
          <div className={styles._body}>
            <ResponseView
              response={Response.responseFailedOtp}
              errorMessage={errorOtpMessage}
              toForm={() => {
                setCurrentView(View.register);
              }}
              toOtpView={() => {
                setCurrentView(View.otpcode);
              }}
              resendOtp={() => {
                resendOTPCode(registrationID!);
              }}
            />
          </div>
        ) : currentView === View.responseSuccess ? (
          <div className={styles._body}>
            <ResponseView response={Response.success} />
          </div>
        ) : null}
        <p style={{ margin: "0", color: "#8F8F8F" }}>
          contattaci a{" "}
          <a
            href="mailto:support@camelot.vote"
            style={{
              textDecoration: "none",
              color: "#0069CA",
              fontWeight: 600,
            }}
          >
            support@camelot.vote{" "}
          </a>
        </p>
      </div>
    </div>
  );
}
