import styles from "./procedura.module.scss";
import closeButton from "../../libs/assets/closeButton.svg";
import { useNavigate } from "react-router";

export default function ProceduraMobile(props: {onClose:() => void}){

    const navigate = useNavigate();

    return(
        <div className={styles.main}>
            <div className={styles.closeButton}>
                <img src={closeButton} alt={"close"} onClick={props.onClose}/>
            </div>

            {/* <div className={styles.online}>
                <p style={{textAlign:"center", color: "#0069CA"}}>PRE REGISTRAZIONE</p>
                <div className={styles.data}>
                    <p>DATA CHIUSURA</p>
                    <strong>4 Dicembre ore 11:00</strong>
                </div>
                    <p> Fino al 4 dicembre gli aventi diritto avranno la possibilità di pre-registrarsi alla votazione, completando prima del voto la procedura di identificazione.</p>
                    <p> Coloro che si saranno pre-registrati riceveranno via mail il link di voto il giorno 11 dicembre. <a style={{color: "#0069CA"}}onClick={()=>navigate("/register")}>Avvia la procedura di pre-registrazione.</a> </p>
                </div>

                <hr style={{backgroundColor: "#0069CA", border: "none",height: "1px", width:"90%"}}></hr> */}

            <div className={styles.online}>
                <p style={{textAlign:"center", color: "#0069CA"}}>VOTO DIGITALE</p>
                <div className={styles.data}>
                    <p>DATA APERTURA</p>
                    <strong>11 Dicembre ore 13:00</strong>
                </div>
                <div className={styles.data}>
                    <p>DATA CHIUSURA</p>
                    <strong>15 Dicembre ore 13:00</strong>
                </div>
                <p> Dal 11 al 15 dicembre sarà possibile votare inserendo le informazioni anagrafiche per verificare l’idoneità dell’elettore a partecipare alle elezioni del Forum dei Giovani del Comune di Napoli.</p>
                <p> Al termine della procedura verrà inviato un link alla casella di posta elettronica personale per accedere direttamente alla piattaforma di voto.</p>
            </div>

            <hr style={{backgroundColor: "#0069CA", border: "none",height: "1px", width:"90%"}}></hr>


            <div className={styles.fisico}>
                <p style={{textAlign:"center", color: "#0069CA"}}>VOTO IN PRESENZA</p>
                <div className={styles.data}>
                    <p>DATA APERTURA</p>
                    <strong>16 Dicembre ore 9:00</strong>
                </div>
                <div className={styles.data}>
                    <p>DATA CHIUSURA</p>
                    <strong>16 Dicembre ore 13:00</strong>
                </div>
                <p>Il 16 Dicembre, presso il seggio allestito a Palazzo San Giacomo in Piazza Municipio, sarà possibile votare di persona dalle ore 9 alle ore 13.</p>
                <p>Dopo l'identificazione dell'elettore svolta dalla Commissione elettorale, si avrà accesso ad una delle urne digitali disponibili per esprimere il proprio voto.</p>
            </div>

        </div>
    )}