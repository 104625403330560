import { useState } from "react";
import styles from "./otpCode.module.scss";
import { MuiOtpInput } from "mui-one-time-password-input";
import CamelotButton from "../CamelotButton";

export default function InsertOtpCodeView(props: {
  onSubmit?: (e: any) => unknown;
  editPhone?: () => void;
  resendOtp?: () => void;
}) {
  const [otp, setOtp] = useState("");

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };
  return (
    <div className={styles.main}>
      <p style={{ color: "#0069CA", textAlign: "center", fontWeight: "500" }}>
        INSERISCI IL CODICE OTP RICEVUTO TRAMITE SMS AL NUMERO DI CELLULARE
        INDICATO NEL FORM
      </p>

      <div className={styles.otpInput}>
        <label>CODICE OTP</label>
        <MuiOtpInput
          value={otp}
          onChange={handleChange}
          className={styles.mui}
          length={6}
        />
      </div>

      <div className={styles.warning}>
        <hr
          style={{
            backgroundColor: "#0069CA",
            border: "none",
            height: "1px",
          }}
        ></hr>
        <p
          className={styles.text}
          style={{
            fontStyle: "italic",
            color: "#8F8F8F",
            margin: "0",
            fontWeight: "600",
          }}
        >
          Dichiaro che i dati inseriti all'atto della registrazione sono
          autentici, completi e corrispondono all'utente che ha eseguito la
          registrazione stessa, consapevole che il conferimento di dati non
          conformi al vero o non appartenenti all'utente o l'effettuazione di
          plurime iscrizioni verranno considerati alla stregua del rilascio di
          false dichiarazioni in scrittura privata, dando luogo alle relative
          responsabilità anche di natura penali.
        </p>
      </div>

      <div className={styles.footer}>
        <div className={styles.resendCode}>
          <p
            style={{
              color: "#FF65DD",
              margin: "0",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            NON HAI RICEVUTO IL CODICE?
          </p>
          <CamelotButton
            width="110px"
            ghost
            text="REINVIA OTP"
            action={() => {
              props.resendOtp && props.resendOtp();
            }}
          />
          {/* <p style={{fontStyle: "italic", color:"#8F8F8F", margin: "0"}}>invia tra x secondi</p> */}
        </div>
        <div className={styles.buttons}>
          <CamelotButton
            text="Modifica numero"
            ghost
            action={() => props.editPhone && props.editPhone()}
          />
          <CamelotButton
            text="Conferma"
            action={async (e) => props.onSubmit && props.onSubmit(otp)}
          />
        </div>
      </div>
    </div>
  );
}
