import styles from "./input.module.scss";

export default function CamelotInput(props: {
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  phoneNumber?: boolean;
  type?: string;
  name?: string;
  autocomplete?: string;
}) {
  return (
    <div className={styles.main}>
      <label>{props.label}</label>
      <div className={styles.inputs}>
        {props.phoneNumber ? (
          <input disabled className={styles.prefisso} value={"+39"} />
        ) : null}
        <input type={props.type} name={props.name} onChange={props.onChange} autoComplete={props.autocomplete}></input>
      </div>
    </div>
  );
}
