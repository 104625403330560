import { Lista } from "../../models/lista";
import styles from "./card.module.scss";
import logo from "../../libs/assets/loghi/briganti_napoletani.png";


export default function CardLista(props: {lista: Lista}){
    return(
        <div className={styles.main}>

            <div className={styles.logo}>
                {/* <img src={logo} alt={"logo"}/> */}
                {/* <img src={props.lista.logo} alt="logo"/> */}
                <img src={`https://cdn.camelot.vote/napoli/${props.lista.logo}.jpg`} alt="logo"/>
            </div>

            <div className={styles.greyWrapper}>
                <p style={{fontStyle: "italic", margin:0, height: "30px", padding:"0 10px"}}>{props.lista.nome}</p>
                <hr style={{backgroundColor: "#0069CA", border: "none",height: "1px", width:"90%"}}></hr>

                <strong style={{color: "#0069CA", padding: "0 10px"}}>LISTA CANDIDATI</strong>

                <div className={styles.lista}>
                    {props.lista.candidati.map((e,index)=>{
                        return <p key={`${props.lista.listID}_${index}`}>{index+1}. {e.cognome.toUpperCase()} {e.nome} </p>
                    })}
                </div>
            </div>
        </div>
    )
}